var exports = {};

/* eslint no-var: off */

/**
 * This file is responsible for building the DOM and updating DOM state.
 *
 * @author Tim Scanlin
 */
exports = function (options) {
  var forEach = [].forEach;
  var some = [].some;
  var body = document.body;
  var tocElement;
  var currentlyHighlighting = true;
  var SPACE_CHAR = " ";
  /**
   * Create link and list elements.
   * @param {Object} d
   * @param {HTMLElement} container
   * @return {HTMLElement}
   */

  function createEl(d, container) {
    var link = container.appendChild(createLink(d));

    if (d.children.length) {
      var list = createList(d.isCollapsed);
      d.children.forEach(function (child) {
        createEl(child, list);
      });
      link.appendChild(list);
    }
  }
  /**
   * Render nested heading array data into a given element.
   * @param {HTMLElement} parent Optional. If provided updates the {@see tocElement} to match.
   * @param {Array} data
   * @return {HTMLElement}
   */


  function render(parent, data) {
    var collapsed = false;
    var container = createList(collapsed);
    data.forEach(function (d) {
      createEl(d, container);
    }); // Return if no TOC element is provided or known.

    tocElement = parent || tocElement;

    if (tocElement === null) {
      return;
    } // Remove existing child if it exists.


    if (tocElement.firstChild) {
      tocElement.removeChild(tocElement.firstChild);
    } // Just return the parent and don't append the list if no links are found.


    if (data.length === 0) {
      return tocElement;
    } // Append the Elements that have been created


    return tocElement.appendChild(container);
  }
  /**
   * Create link element.
   * @param {Object} data
   * @return {HTMLElement}
   */


  function createLink(data) {
    var item = document.createElement("li");
    var a = document.createElement("a");

    if (options.listItemClass) {
      item.setAttribute("class", options.listItemClass);
    }

    if (options.onClick) {
      a.onclick = options.onClick;
    }

    if (options.includeTitleTags) {
      a.setAttribute("title", data.textContent);
    }

    if (options.includeHtml && data.childNodes.length) {
      forEach.call(data.childNodes, function (node) {
        a.appendChild(node.cloneNode(true));
      });
    } else {
      // Default behavior.
      a.textContent = data.textContent;
    }

    a.setAttribute("href", options.basePath + "#" + data.id);
    a.setAttribute("class", options.linkClass + SPACE_CHAR + "node-name--" + data.nodeName + SPACE_CHAR + options.extraLinkClasses);
    item.appendChild(a);
    return item;
  }
  /**
   * Create list element.
   * @param {Boolean} isCollapsed
   * @return {HTMLElement}
   */


  function createList(isCollapsed) {
    var listElement = options.orderedList ? "ol" : "ul";
    var list = document.createElement(listElement);
    var classes = options.listClass + SPACE_CHAR + options.extraListClasses;

    if (isCollapsed) {
      classes += SPACE_CHAR + options.collapsibleClass;
      classes += SPACE_CHAR + options.isCollapsedClass;
    }

    list.setAttribute("class", classes);
    return list;
  }
  /**
   * Update fixed sidebar class.
   * @return {HTMLElement}
   */


  function updateFixedSidebarClass() {
    if (options.scrollContainer && document.querySelector(options.scrollContainer)) {
      var top;
      top = document.querySelector(options.scrollContainer).scrollTop;
    } else {
      top = document.documentElement.scrollTop || body.scrollTop;
    }

    var posFixedEl = document.querySelector(options.positionFixedSelector);

    if (options.fixedSidebarOffset === "auto") {
      options.fixedSidebarOffset = tocElement.offsetTop;
    }

    if (top > options.fixedSidebarOffset) {
      if (posFixedEl.className.indexOf(options.positionFixedClass) === -1) {
        posFixedEl.className += SPACE_CHAR + options.positionFixedClass;
      }
    } else {
      posFixedEl.className = posFixedEl.className.split(SPACE_CHAR + options.positionFixedClass).join("");
    }
  }
  /**
   * Get top position of heading
   * @param {HTMLElement} obj
   * @return {int} position
   */


  function getHeadingTopPos(obj) {
    var position = 0;

    if (obj !== null) {
      position = obj.offsetTop;

      if (options.hasInnerContainers) {
        position += getHeadingTopPos(obj.offsetParent);
      }
    }

    return position;
  }
  /**
   * Update TOC highlighting and collpased groupings.
   */


  function updateToc(headingsArray) {
    // If a fixed content container was set
    if (options.scrollContainer && document.querySelector(options.scrollContainer)) {
      var top;
      top = document.querySelector(options.scrollContainer).scrollTop;
    } else {
      top = document.documentElement.scrollTop || body.scrollTop;
    } // Add fixed class at offset


    if (options.positionFixedSelector) {
      updateFixedSidebarClass();
    } // Get the top most heading currently visible on the page so we know what to highlight.


    var headings = headingsArray;
    var topHeader; // Using some instead of each so that we can escape early.

    if (currentlyHighlighting && tocElement !== null && headings.length > 0) {
      some.call(headings, function (heading, i) {
        if (getHeadingTopPos(heading) > top + options.headingsOffset + 10) {
          // Don't allow negative index value.
          var index = i === 0 ? i : i - 1;
          topHeader = headings[index];
          return true;
        } else if (i === headings.length - 1) {
          // This allows scrolling for the last heading on the page.
          topHeader = headings[headings.length - 1];
          return true;
        }
      }); // Remove the active class from the other tocLinks.

      var tocLinks = tocElement.querySelectorAll("." + options.linkClass);
      forEach.call(tocLinks, function (tocLink) {
        tocLink.className = tocLink.className.split(SPACE_CHAR + options.activeLinkClass).join("");
      });
      var tocLis = tocElement.querySelectorAll("." + options.listItemClass);
      forEach.call(tocLis, function (tocLi) {
        tocLi.className = tocLi.className.split(SPACE_CHAR + options.activeListItemClass).join("");
      }); // Add the active class to the active tocLink.

      var activeTocLink = tocElement.querySelector("." + options.linkClass + ".node-name--" + topHeader.nodeName + "[href=\"" + options.basePath + "#" + topHeader.id.replace(/([ #;&,.+*~':"!^$[\]()=>|/@])/g, "\\$1") + "\"]");

      if (activeTocLink && activeTocLink.className.indexOf(options.activeLinkClass) === -1) {
        activeTocLink.className += SPACE_CHAR + options.activeLinkClass;
      }

      var li = activeTocLink && activeTocLink.parentNode;

      if (li && li.className.indexOf(options.activeListItemClass) === -1) {
        li.className += SPACE_CHAR + options.activeListItemClass;
      }

      var tocLists = tocElement.querySelectorAll("." + options.listClass + "." + options.collapsibleClass); // Collapse the other collapsible lists.

      forEach.call(tocLists, function (list) {
        if (list.className.indexOf(options.isCollapsedClass) === -1) {
          list.className += SPACE_CHAR + options.isCollapsedClass;
        }
      }); // Expand the active link's collapsible list and its sibling if applicable.

      if (activeTocLink && activeTocLink.nextSibling && activeTocLink.nextSibling.className.indexOf(options.isCollapsedClass) !== -1) {
        activeTocLink.nextSibling.className = activeTocLink.nextSibling.className.split(SPACE_CHAR + options.isCollapsedClass).join("");
      }

      removeCollapsedFromParents(activeTocLink && activeTocLink.parentNode.parentNode);
    }
  }
  /**
   * Remove collpased class from parent elements.
   * @param {HTMLElement} element
   * @return {HTMLElement}
   */


  function removeCollapsedFromParents(element) {
    if (element && element.className.indexOf(options.collapsibleClass) !== -1 && element.className.indexOf(options.isCollapsedClass) !== -1) {
      element.className = element.className.split(SPACE_CHAR + options.isCollapsedClass).join("");
      return removeCollapsedFromParents(element.parentNode.parentNode);
    }

    return element;
  }
  /**
   * Disable TOC Animation when a link is clicked.
   * @param {Event} event
   */


  function disableTocAnimation(event) {
    var target = event.target || event.srcElement;

    if (typeof target.className !== "string" || target.className.indexOf(options.linkClass) === -1) {
      return;
    } // Bind to tocLink clicks to temporarily disable highlighting
    // while smoothScroll is animating.


    currentlyHighlighting = false;
  }
  /**
   * Enable TOC Animation.
   */


  function enableTocAnimation() {
    currentlyHighlighting = true;
  }

  return {
    enableTocAnimation: enableTocAnimation,
    disableTocAnimation: disableTocAnimation,
    render: render,
    updateToc: updateToc
  };
};

export default exports;